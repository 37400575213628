import {
  createBrowserRouter,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Connect from "./components/connect/connect";
import Newpassword from "./components/newPassword/newpassword";
import Signin from "./components/signin/signin";
import Signup from "./components/signup/signup";
import Verifycode from "./components/verifyCode/verifyCode";
import Dashboard from "./components/dashboard/dashboard";
import AgentCreation from "./components/agentCreation/agentCreation";
import Script from "./components/script/script";
import AdvanceScript from "./components/advanceScript/advanceScript";
import Agents from "./components/agentspage/agents/agents";
import Knowledgebase from "./components/knowledgebasepage/knowledgebase/knowledgebase";
import ManualKnowlwdgebase from "./components/knowledgebasepage/manualKnowledgebase/manualKnowlwdgebase";
import Documentedknowledgebase from "./components/knowledgebasepage/documentedknowlwdgebase/documentedknowledgebase";
import Settings from "./components/settings/settings";
import Reminders from "./components/reminders/reminders";
import RemindersHistory from "./components/remindersHistory/remindersHistory";
import Monitoring from "./components/monitoring/monitoring";
import Contactslist from "./components/contactsList/contactslist";
import Createcompaign from "./components/compaign/createCompaign/createcompaign";
import CompaignsInrun from "./components/compaign/compaignsInrun/compaignsInrun";
import DashOutlet from "./components/dashboard/DashOutlet";
import DashboardMain from "./components/dashboard/dashboardMain";
import Recovery from "./components/passwordRecover/passwordRecovery";
import { toast, ToastContainer } from "react-toastify";
import { createContext, useContext, useEffect, useState } from "react";
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";
import ErrorPage from "./components/errorPage/errorPage";
import CampaignDetails from "./components/compaign/campaigndetails/campaignDetails";
import History from "./components/history/history";
import axios from "axios";
import UpdateCompaign from "./components/compaign/createCompaign/updateCompaign";

export const DataContext = createContext({
  _id: "",
  customerID: "",
  AgentName: "",
  CompanyName: "",
  FallBackNote:
    "If you're unsure about the type of loan you need, I can help clarify options based on your business needs and financial goals. Let's explore the best solution together.",
  audioId: "",
  greetings:
    "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?",
  Temperature: 0.2,
  Maximum_Tokens: "654",
  Top_P: "1",
  Frequency_Penality: "0",
  Presence_Penality: "0",
  Model: "",
  Script: "",
  Scriptshow: `**Introduction:**
"Hello [Client First Name], this is {{agent_name}} from {{company_name}}. I am calling to inform you about our new SBA FastTrack program, designed for businesses looking to expand. It offers up to $350,000 in working capital with a 10-year term and monthly payments. Does your business need any working capital at this time?"
Your Role:
If the user's query is from the knowledge base, don't answer off the cuff; refer directly to the knowledge base.
Personal Information:
- Your name is {agent_name}.
- Your company name is {company_name}.
**Qualifying Questions:**
1. How long has your business been operating?
2. Could you share what your business revenue looked like last year?
3. Do you know your approximate FICO score?
(Minimum of 2 years required)
(Minimum of $200,000 required)
(Minimum of 640 required)
"Base of above question told him if he qualified for loan"
**Scheduling the Consultation:**
"Based on your responses, it sounds like you are a great fit for this program. Let's schedule a consultation to discuss this further. How does [Day], [Date] at [Time] work for you? If that doesn’t work, I have other times available."
**Confirmation of Appointment:**
"Perfect, you're all set for [Day], [Date] at [Time]. You will be speaking with [Loan Officer], who is an expert in SBA loans. You will soon receive an email with a link to a secure loan application. Completing this before our meeting will ensure we make the most out of our time together."
**Objection Handling Tips:**
- Listen attentively and allow the prospect to fully express their concerns.
- Acknowledge and validate the prospect's feelings to build trust.
- Determine the root of any objections to better address them and move towards closing the conversation positively.
**Advanced Objection Handling Techniques:**
- "If a prospect is concerned about the time commitment, reassure them that our streamlined process minimizes downtime and maximizes efficiency."
- "Should the topic of financial readiness arise, discuss our flexible terms that are designed to accommodate various financial states, emphasizing the supportive nature of our program.", confinement:"Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance. If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved. Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments. Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements. Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client. Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive. Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.
- Send meeting link to email.`,

  confinement: `Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance.

If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved.

Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments.

Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements.

Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client.

Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive.

Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.`,

  knowledgeBaseId: "",
  phoneNumber: "",
  hubspot_meetinglink: "",
  hubspot_meeting_id: "",
  hubspot_meeting_slug: "",
  hubspot_meeting_name: "",
  hubspot_meeting_type: "",
  hubspot_meeting_organizer_userId: "",
  hubspot_meeting_userIds_of_link_members: [],
});

export const CampaignDataContext = createContext({});
export const LoginContext = createContext({});
export const UserNameContext = createContext({});
export const UserImageContext = createContext({});
export const UserHubspotContext = createContext({});
const AppLayout = () => {
  const [data, setData] = useState({
    _id: "",
    customerID: "",
    AgentName: "",
    AgentTitle: "",
    CompanyName: "",
    FallBackNote:
      "If you're unsure about the type of loan you need, I can help clarify options based on your business needs and financial goals. Let's explore the best solution together.",
    audioId: "",
    greetings:
      "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?",
    Temperature: 0.2,
    Maximum_Tokens: "654",
    Top_P: "1",
    Frequency_Penality: "0",
    Presence_Penality: "0",
    Model: "",
    Script: "",
    Scriptshow: `**Introduction:**
"Hello [Client First Name], this is {{agent_name}} from {{company_name}}. I am calling to inform you about our new SBA FastTrack program, designed for businesses looking to expand. It offers up to $350,000 in working capital with a 10-year term and monthly payments. Does your business need any working capital at this time?"
Your Role:
If the user's query is from the knowledge base, don't answer off the cuff; refer directly to the knowledge base.
Personal Information:
- Your name is {agent_name}.
- Your company name is {company_name}.
**Qualifying Questions:**
1. How long has your business been operating?
2. Could you share what your business revenue looked like last year?
3. Do you know your approximate FICO score?
(Minimum of 2 years required)
(Minimum of $200,000 required)
(Minimum of 640 required)
"Base of above question told him if he qualified for loan"
**Scheduling the Consultation:**
"Based on your responses, it sounds like you are a great fit for this program. Let's schedule a consultation to discuss this further. How does [Day], [Date] at [Time] work for you? If that doesn’t work, I have other times available."
**Confirmation of Appointment:**
"Perfect, you're all set for [Day], [Date] at [Time]. You will be speaking with [Loan Officer], who is an expert in SBA loans. You will soon receive an email with a link to a secure loan application. Completing this before our meeting will ensure we make the most out of our time together."
**Objection Handling Tips:**
- Listen attentively and allow the prospect to fully express their concerns.
- Acknowledge and validate the prospect's feelings to build trust.
- Determine the root of any objections to better address them and move towards closing the conversation positively.
**Advanced Objection Handling Techniques:**
- "If a prospect is concerned about the time commitment, reassure them that our streamlined process minimizes downtime and maximizes efficiency."
- "Should the topic of financial readiness arise, discuss our flexible terms that are designed to accommodate various financial states, emphasizing the supportive nature of our program.", confinement:"Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance. If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved. Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments. Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements. Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client. Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive. Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.
- Send meeting link to email.`,
    confinement: `Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance.

If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved.

Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments.

Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements.

Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client.

Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive.

Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.`,
    knowledgeBaseId: "",
    phoneNumber: "",
    hubspot_meetinglink: "",
    hubspot_meeting_id: "",
    hubspot_meeting_slug: "",
    hubspot_meeting_name: "",
    hubspot_meeting_type: "",
    hubspot_meeting_organizer_userId: "",
    hubspot_meeting_userIds_of_link_members: [],
  });
  const [socketData, setSocketData] = useState({});
  const [buttonStatus, setButtonStatus] = useState("start");
  const [updateagent, setUpdateagent] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [userName, setUserName] = useState("");
  const [imgData, setImgData] = useState("");
  const [hubspotData, setHubspotData] = useState("");
  const [isActive, setIsActive] = useState([]);
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <UserHubspotContext.Provider value={{ hubspotData, setHubspotData }}>
        <UserNameContext.Provider value={{ userName, setUserName }}>
          <UserImageContext.Provider value={{ imgData, setImgData }}>
            <LoginContext.Provider value={{ loginStatus, setLoginStatus }}>
              <CampaignDataContext.Provider
                value={{
                  socketData,
                  setSocketData,
                  buttonStatus,
                  setButtonStatus,
                  isActive,
                  setIsActive,
                }}
              >
                <DataContext.Provider
                  value={{
                    data: data,
                    setData: setData,
                    updateagent: updateagent,
                    setUpdateagent: setUpdateagent,
                  }}
                >
                  <ToastContainer />
                  <RouterProvider router={appRouter} />
                </DataContext.Provider>
              </CampaignDataContext.Provider>
            </LoginContext.Provider>
          </UserImageContext.Provider>
        </UserNameContext.Provider>
      </UserHubspotContext.Provider>
    </>
  );
};
const AppNew = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  // {
  //   path: "/signup",
  //   element: <Signup />,
  // },
  {
    path: "/password-recovery",
    element: <Recovery />,
  },
  {
    path: "/verify-code/:email",
    element: <Verifycode />,
  },

  {
    path: "/new-password/:email",
    element: <Newpassword />,
  },
  {
    path: "/connect-to-hubspot",
    element: <Connect />,
  },

  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <Dashboard />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "/dashboard",
        element: <DashOutlet />,
        children: [
          {
            path: "/dashboard",
            element: <DashboardMain />,
          },
        ],
      },

      {
        path: "/agents",
        element: <Agents />,
      },
      {
        path: "/create-agent",
        element: <AgentCreation />,
      },
      {
        path: "/script",
        element: <Script />,
      },
      {
        path: "/advanced",
        element: <AdvanceScript />,
      },

      {
        path: "/reminders",
        element: <Reminders />,
      },
      {
        path: "/reminders-history",
        element: <RemindersHistory />,
      },

      {
        path: "/knowledge-base",
        element: <Knowledgebase />,
      },

      {
        path: "/contacts-list",
        element: <Contactslist />,
      },
      {
        path: "/campaigns",
        element: <CompaignsInrun />,
      },
      {
        path: "/create-campaign",
        element: <Createcompaign />,
      },
      {
        path: "/update-campaign",
        element: <UpdateCompaign />,
      },
      {
        path: "/campaign-details/:id",
        element: <CampaignDetails />,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
]);

export default AppLayout;
