import React, { useContext, useEffect, useRef, useState } from "react";
import "./contactslist.scss";
import Button from "../button/button";
import { Modal } from "react-bootstrap";
import Inputdash from "../Inputdash/inputdash";
import axios from "axios";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { UserHubspotContext } from "../../App";

function Contactslist() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const Budurl = process.env.REACT_APP_AWS_BASE_URL;
  const { hubspotData, setHubspotData } = useContext(UserHubspotContext);
  const [selectagent, setSelectagent] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [show, setShow] = useState(false);
  const [listName, setListName] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [hubspotList, setHubspotList] = useState([]);
  const [hubspotContacts, setHubspotContacts] = useState([]);
  const [hubspotContactsloader, setHubspotContactsLoader] = useState(false);
  const [hubspotModal, setHubspotModal] = useState(false);
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [deletemodal, setDeletemodal] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [loader, setLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [hubspotLoader, setHubspotLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search state for lists
  const [searchHubspotLists, setSearchHubspotLists] = useState(""); // Search state for lists
  const [contactSearchTerm, setContactSearchTerm] = useState(""); // Search state for contacts in modal
  const [csvModal, setCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]); // CSV data state
  const [csvSearchTerm, setCsvSearchTerm] = useState(""); // CSV search state
  const [fileInput, setFileInput] = useState(null);
  const [btnLoader, setbtnLoader] = useState(false);
  const handleClose = () => setShow(false);
  const handleListClose = () => {
    setListName(false);
    setShow(true);
  };
  const handledeleteclose = () => {
    setDeleteid("");
    setDeletemodal(false);
  };
  // Handle file input when CSV button is clicked
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseCSV(file);
    }
  };
  //
  // Parse CSV file
  const parseCSV = (file) => {
    try {
      // Using Papa Parse to parse the CSV file
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const parsedData = results.data;
          console.log(parsedData);

          // Check for "number" or "Number" field and throw an error if present
          const hasInvalidFields = parsedData.some(
            (row) =>
              row.hasOwnProperty("number") || row.hasOwnProperty("Number")
          );

          if (hasInvalidFields) {
            toast.error(
              "CSV file should not contain 'number' or 'Number' fields."
            );
            return;
          }

          // Validate CSV columns (email, name, or phone should be present)
          const hasRequiredFields = parsedData.some(
            (row) =>
              row.hasOwnProperty("email") ||
              row.hasOwnProperty("Email") ||
              row.hasOwnProperty("name") ||
              row.hasOwnProperty("Name") ||
              row.hasOwnProperty("phone") ||
              row.hasOwnProperty("Phone")
          );

          if (hasRequiredFields) {
            setCsvData(parsedData);
            setContactModal(false);
            setCsvModal(true); // Open CSV modal with parsed data
          } else {
            toast.error(
              "CSV file must contain at least email, name, or phone fields."
            );
          }
        },
        error: (error) => {
          toast.error("Failed to parse CSV file");
        },
      });
    } catch (error) {
      // Handle any unexpected errors here
      toast.error("An unexpected error occurred: " + error.message);
    }
  };

  const crateHubspotList = () => {
    setbtnLoader(true);
    if (contacts.length === 0) {
      toast.error("Please select at least one contact", {
        toastId: "empty",
      });
      setbtnLoader(false);
      return;
    } else {
      listApi();
    }
  };
  // Filtered data for CSV modal
  const filteredCsvData = csvData.filter(
    (item) =>
      item.name?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.Name?.toLowerCase().includes(csvSearchTerm.toLowerCase())
    // item.email?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
    // item.Email?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
    // item.phone?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
    // item.Phone?.toLowerCase().includes(csvSearchTerm.toLowerCase())
  );
  const handledeleteshow = () => {
    setDeletemodal(true);
  };
  const handleListShow = () => {
    if (contacts.length === 0) {
      toast.error("Please select at least one contact", { toastId: "empty" });
      return;
    }
    setListName(true);
  };

  // List create API
  const listApi = () => {
    if (!fileName) {
      toast.error("Please add name", { toastId: "empty" });
      return;
    } else {
      setbtnLoader(true);
      const options = {
        method: "POST",
        url: `${Apiurl}/contacts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          fileName: fileName,
          lists: contacts,
        },
      };
      axios
        .request(options)
        .then(function (response) {
          handleListClose();
          setListName(false);
          setContactModal(false);
          setCsvModal(false);
          setContacts([]);
          getListApi();
          setbtnLoader(false);
          setHubspotModal(false);
          toast.success("List Created Successfully", { toastId: "createlist" });
        })
        .catch(function (error) {
          toast.error(error, { toastId: "listerror" });
          setbtnLoader(false);
        });
    }
  };

  // All lists API
  const getListApi = () => {
    setDataLoader(true);
    const options = {
      method: "GET",
      url: `${Apiurl}/getallcontacts`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setData(response.data);
        setDataLoader(false);
      })
      .catch(function (error) {
        setDataLoader(false);
      });
  };

  // HubspotContactsList
  const getHubspotListApi = () => {
    setHubspotLoader(true);
    console.log(hubspotData);

    const options = {
      method: "GET",
      url: `${Budurl}/lists`,
      headers: {
        Authorization: `Bearer ${hubspotData}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setHubspotList(response.data.lists);
        setHubspotLoader(false);
      })
      .catch(function (error) {
        console.log("Error", error);
        setHubspotLoader(false);
      });
  };

  const getHubspotContacts = (id) => {
    setHubspotContactsLoader(true);
    const options = {
      method: "GET",
      url: `${Budurl}/lists/${id}`,
      headers: {
        Authorization: `Bearer ${hubspotData}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setHubspotContacts(response.data.contacts);
        setHubspotContactsLoader(false);
      })
      .catch(function (error) {
        console.log("Error", error);
        setHubspotContactsLoader(false);
      });
  };
  // Delete List API
  const deleteList = () => {
    setLoader(true);
    const options = {
      method: "DELETE",
      url: `${Apiurl}/contacts/${deleteid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .request(options)
      .then(function () {
        getListApi();
        setLoader(false);
        toast.success("List Deleted Successfully", { toastId: "deletelist" });
        handledeleteclose();
      })
      .catch(function (error) {
        toast.error(error);
        setLoader(false);
      });
  };

  // All Contacts API
  const [allContacts, setAllContacts] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchContacts = async () => {
    setError(null);
    setLoading(true);

    try {
      const requestBody = nextPageToken ? { after: nextPageToken } : {};
      const options = {
        method: "POST",
        url: `${Apiurl}/getContactFromHupspot`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: requestBody,
      };
      const response = await axios.request(options);

      setAllContacts((prevContacts) => [
        ...prevContacts,
        ...response.data.data,
      ]);

      setNextPageToken(response.data.nextPageToken || null);
    } catch (err) {
      setError("Failed to fetch contacts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHubspotListApi();
    fetchContacts();
    getListApi();
  }, []);

  const filteredData = data.filter((item) =>
    item.fileName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredHubspotLists = hubspotList?.filter((item) =>
    item?.name.toLowerCase().includes(searchHubspotLists.toLowerCase())
  );
  const filteredContacts = allContacts.filter((item) => {
    const firstName = item?.properties?.firstname || ""; // Default to empty string if null
    const lastName = item?.properties?.lastname || ""; // Default to empty string if null
    const email = item?.properties?.email || ""; // Default to empty string if null

    return (
      firstName.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
      lastName.toLowerCase().includes(contactSearchTerm.toLowerCase())
      // email.toLowerCase().includes(contactSearchTerm.toLowerCase())
    );
  });

  return (
    <div className="dash-main">
      <section className="contactlist-sec mb-3">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">
            Lists from Hubspot
          </h3>
        </div>
        <div className="contacts">
          <div className="inner-top d-flex align-center justify-content-between gap-4">
            <div>
              <p className="greycolor Carmen-medium-font">All Lists</p>
            </div>
          </div>
          <div className="contacts-inner">
            <div className="topbar">
              <div className="search-bar">
                <img
                  src="/assets/icons/search.svg"
                  alt="Search icon"
                  className="pointer"
                />
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchHubspotLists}
                  onChange={(e) => setSearchHubspotLists(e.target.value)}
                />
              </div>
            </div>
            <div className="contactTable-outer">
              <div className="contact-table">
                <div className="table-body customscrollbar">
                  {hubspotLoader ? (
                    <div
                      className="d-flex align-items-center m-auto "
                      style={{ height: "100px" }}
                    >
                      <span className="spinner"></span>
                    </div>
                  ) : filteredHubspotLists?.length > 0 ? (
                    filteredHubspotLists?.map((item) => (
                      <div className="contact-detail" key={item.listId}>
                        <div className="second w-100 d-flex justify-content-between align-items-center gap-5">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.name}
                          </p>
                          <Button
                            text="Select"
                            className="primary-button w-fit fs-16"
                            maxWidth="130px"
                            padding="8px 14px"
                            onClick={() => {
                              setHubspotModal(true);
                              getHubspotContacts(item.listId);
                              setFileName(item.name);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="mx-auto py-4">No data</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactlist-sec">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">Lists</h3>
          <Button
            text="Create new list"
            className="primary-button w-100 fs-16"
            maxWidth="160px"
            width="100%"
            padding="10px 16px"
            onClick={() => {
              setContactModal(true);
            }}
          />
        </div>
        <div className="contacts">
          <div className="inner-top d-flex align-center justify-content-between gap-4">
            <div>
              <p className="greycolor Carmen-medium-font">All Lists</p>
            </div>
          </div>
          <div className="contacts-inner">
            <div className="topbar">
              <div className="search-bar">
                <img
                  src="/assets/icons/search.svg"
                  alt="Search icon"
                  className="pointer"
                />
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Separate search term for lists
                />
              </div>
            </div>
            <div className="contactTable-outer">
              <div className="contact-table">
                <div className="table-body customscrollbar">
                  {dataLoader ? (
                    <div
                      className="d-flex align-items-center m-auto "
                      style={{ height: "100px" }}
                    >
                      <span className="spinner"></span>
                    </div>
                  ) : filteredData.length > 0 ? (
                    filteredData.map((item) => (
                      <div className="contact-detail" key={item._id}>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.fileName}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.lists.length}
                          </p>
                        </div>
                        <div className="forth d-flex align-items-center justify-between gap-4">
                          <img
                            src="/assets/icons/delete.svg"
                            alt="Delete icon"
                            className="pointer"
                            onClick={() => {
                              setDeleteid(item._id);
                              handledeleteshow();
                            }}
                          />
                          <img
                            src="/assets/icons/view.svg"
                            alt="View icon"
                            className="pointer"
                            onClick={() => {
                              setViewModal(true);
                              setViewData(item.lists);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="mx-auto py-4">No data</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Creating a New List */}
        <Modal
          size="lg"
          show={contactModal}
          centered
          onHide={() => {
            setContactModal(false);
            setContacts([]);
          }}
          className="contact-modal"
        >
          <Modal.Body>
            <h2 className="primarycolor Carmen-semibold-font text-center">
              Create new list
            </h2>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => {
                setContactModal(false);
                setContacts([]);
              }}
            />
            <div className="contactsModal-inner">
              <div className="topbar">
                <div className="search-bar">
                  <img
                    src="/assets/icons/search.svg"
                    alt="Search icon"
                    className="pointer"
                  />
                  <input
                    type="text"
                    placeholder="Search here"
                    value={contactSearchTerm}
                    onChange={(e) => setContactSearchTerm(e.target.value)} // Separate search term for contacts
                  />
                </div>
                <Button
                  text="Upload CSV"
                  className="primary-button w-100 fs-16"
                  maxWidth="130px"
                  width="100%"
                  padding="8px 10px"
                  onClick={() => fileInput.click()} // Trigger file input
                />
                <input
                  type="file"
                  accept=".csv"
                  ref={(input) => setFileInput(input)}
                  style={{ display: "none" }}
                  onChange={handleFileInput}
                />
              </div>
              <div className="contactmodalTable-outer">
                <div className="contactmodal-table">
                  <div className="table-body customscrollbar">
                    <div className="contact-detail">
                      <div className="ist">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setContacts(
                              isChecked
                                ? filteredContacts.map((item) => ({
                                    id: item.id,
                                    name: `${item.properties.firstname} ${item.properties.lastname}`,
                                    phone:
                                      item.properties.phone ||
                                      "+1 (415) 555‑0132",
                                    email:
                                      item.properties.email || "user@gmail.com",
                                  }))
                                : []
                            );
                          }}
                          checked={
                            filteredContacts.length > 0 &&
                            contacts.length === filteredContacts.length
                          }
                        />
                      </div>
                      <div className="second">
                        <h6 className="blackcolor Carmen-bold-font">Name</h6>
                      </div>
                      <div className="third">
                        <h6 className="blackcolor Carmen-bold-font">Phone</h6>
                      </div>
                      <div className="forth">
                        <h6 className="blackcolor Carmen-bold-font">Email</h6>
                      </div>
                    </div>
                    {filteredContacts.map((item) => (
                      <div className="contact-detail" key={item.id}>
                        <div className="ist">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setContacts((prev) =>
                                isChecked
                                  ? [
                                      ...prev,
                                      {
                                        id: item.id,
                                        name: `${item.properties.firstname} ${item.properties.lastname}`,
                                        phone:
                                          item.properties.phone ||
                                          "+1 (415) 555‑0132",
                                        email:
                                          item.properties.email ||
                                          "user@gmail.com",
                                      },
                                    ]
                                  : prev.filter(
                                      (contact) => contact.id !== item.id
                                    )
                              );
                            }}
                            checked={contacts.some(
                              (contact) => contact.id === item.id
                            )}
                          />
                        </div>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.firstname +
                              " " +
                              item.properties.lastname}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.phone}
                          </p>
                        </div>
                        <div className="forth">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.email}
                          </p>
                        </div>
                      </div>
                    ))}
                    {loading ? (
                      <span className="spinner mx-auto my-3"></span>
                    ) : filteredContacts.length < 1 ? (
                      <p className="mx-auto py-4">No data</p>
                    ) : filteredContacts.length > 20 ? (
                      <Button
                        text="Load more"
                        className="secondary-btn w-100 fs-16 mx-auto mt-3 bg-transparent"
                        maxWidth="120px"
                        padding="10px 10px"
                        onClick={fetchContacts}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <Button
                text="Create"
                className="primary-button w-100 fs-16 ms-auto"
                maxWidth="98px"
                padding="10px 20px"
                onClick={handleListShow}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={csvModal}
          onHide={() => setCsvModal(false)}
          centered
          className="contact-modal"
        >
          <Modal.Body>
            <h2 className="primarycolor Carmen-semibold-font text-center">
              CSV Data
            </h2>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => setCsvModal(false)}
            />
            <div className="contactsModal-inner">
              <div className="topbar">
                <div className="search-bar">
                  <img
                    src="/assets/icons/search.svg"
                    alt="Search icon"
                    className="pointer"
                  />
                  <input
                    type="text"
                    placeholder="Search here"
                    value={csvSearchTerm}
                    name="csvSearchTerm"
                    onChange={(e) => setCsvSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="contactmodalTable-outer">
                <div className="contactmodal-table">
                  <div className="table-body customscrollbar">
                    <div className="contact-detail">
                      <div className="ist">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setContacts(
                              isChecked
                                ? filteredCsvData
                                    .slice(0, -1)
                                    .map((item, index) => ({
                                      id: index, // Use the index as ID
                                      name: item.Name || item.name || "User",
                                      phone:
                                        item.Phone ||
                                        item.phone ||
                                        "+1 (415) 555‑0132",
                                      email:
                                        item.Email ||
                                        item.email ||
                                        "User@gmail.com",
                                    }))
                                : []
                            );
                          }}
                          checked={
                            filteredCsvData.length > 1 &&
                            contacts.length === filteredCsvData.length - 1
                          }
                        />
                      </div>
                      <div className="second">
                        <h6 className="blackcolor Carmen-bold-font">Name</h6>
                      </div>
                      <div className="third">
                        <h6 className="blackcolor Carmen-bold-font">Phone</h6>
                      </div>
                      <div className="forth">
                        <h6 className="blackcolor Carmen-bold-font">Email</h6>
                      </div>
                    </div>
                    {filteredCsvData.filter((data) => data.Name != "").length >
                    0 ? (
                      filteredCsvData
                        .filter((data) => data.Name != "")
                        .map((item, index) =>
                          filteredCsvData.length > 0 ? (
                            <>
                              <div className="contact-detail" key={index}>
                                <div className="ist">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      setContacts((prev) =>
                                        isChecked
                                          ? [
                                              ...prev,
                                              {
                                                id: index, // Use index as ID
                                                name:
                                                  item.Name ||
                                                  item.name ||
                                                  "User",
                                                phone:
                                                  item.Phone ||
                                                  item.phone ||
                                                  "+1 (415) 555‑0132",
                                                email:
                                                  item.Email ||
                                                  item.email ||
                                                  "User@gmail.com",
                                              },
                                            ]
                                          : prev.filter(
                                              (contact) => contact.id !== index
                                            )
                                      );
                                    }}
                                    checked={contacts.some(
                                      (contact) => contact.id === index
                                    )}
                                  />
                                </div>
                                <div className="second">
                                  <p className="small-para greycolor Carmen-regular-font">
                                    {item.Name || item.name || "User"}
                                  </p>
                                </div>
                                <div className="third">
                                  <p className="small-para greycolor Carmen-regular-font">
                                    {item.Phone ||
                                      item.phone ||
                                      "+1 (415) 555‑0132"}
                                  </p>
                                </div>
                                <div className="forth">
                                  <p className="small-para greycolor Carmen-regular-font">
                                    {item.Email ||
                                      item.email ||
                                      "user@gmail.com"}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <p className="mx-auto py-4">No data</p>
                          )
                        )
                    ) : (
                      <p className="mx-auto py-4">No data</p>
                    )}
                  </div>
                </div>
                <Button
                  text="Create"
                  className="primary-button w-100 fs-16 ms-auto"
                  maxWidth="98px"
                  padding="10px 20px"
                  onClick={handleListShow}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal for Naming the List */}
        <Modal
          show={listName}
          onHide={() => {
            setListName(false);
          }}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => {
                setListName(false);
              }}
            />
            <h2 className="Carmen-semibold-font primarycolor">Add list name</h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Add Name"
                placeholder="Type name here"
                name="fileName"
                onchange={(e) => {
                  setFileName(e.target.value);
                }}
              />
              <Button
                text="Add"
                className="primary-button w-100 fs-16"
                maxWidth="100px"
                padding="10px 16px"
                onClick={listApi}
                disabled={btnLoader ? true : false}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete list modal */}
        <Modal
          show={deletemodal}
          onHide={handledeleteclose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handledeleteclose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={deleteList}
                disabled={loader}
              />
              <Button
                text="No"
                className="primary-button fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handledeleteclose}
              />
            </div>
          </Modal.Body>
        </Modal>
      </section>
      {/* View Modal */}

      <Modal
        size="lg"
        show={viewModal}
        centered
        onHide={() => {
          setViewModal(false);
          setViewData([]);
        }}
        className="contact-modal"
      >
        <Modal.Body>
          <h2 className="primarycolor Carmen-semibold-font text-center">
            Contacts
          </h2>
          <img
            src="/assets/icons/modalclose.svg"
            alt="Close"
            className="pointer close-btn"
            onClick={() => {
              setViewModal(false);
              setViewData([]);
            }}
          />
          <div className="contactsModal-inner">
            <div className="contactmodalTable-outer">
              <div className="contactmodal-table">
                <div className="table-body customscrollbar">
                  <div className="contact-detail">
                    <div className="ist"></div>
                    <div className="second">
                      <h6 className="blackcolor Carmen-bold-font">Name</h6>
                    </div>
                    <div className="third">
                      <h6 className="blackcolor Carmen-bold-font">Phone</h6>
                    </div>
                    <div className="forth">
                      <h6 className="blackcolor Carmen-bold-font">Email</h6>
                    </div>
                  </div>
                  {viewData.map((item) => (
                    <div className="contact-detail" key={item.id}>
                      <div className="second">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.name}
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.phone}
                        </p>
                      </div>
                      <div className="forth">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.email}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={hubspotModal}
        onHide={() => {
          setHubspotModal(false);
          setListName("");
          setContacts([]);
        }}
        centered
        className="contact-modal"
      >
        <Modal.Body>
          <h2 className="primarycolor Carmen-semibold-font text-center">
            Create new list
          </h2>
          <img
            src="/assets/icons/modalclose.svg"
            alt="Close"
            className="pointer close-btn"
            onClick={() => {
              setHubspotModal(false);
              setListName("");
              setContacts([]);
            }}
          />
          <div className="contactsModal-inner">
            <div className="contactmodalTable-outer">
              <div className="contactmodal-table">
                <div className="table-body customscrollbar">
                  <div className="contact-detail">
                    <div className="ist">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setContacts(
                            isChecked
                              ? hubspotContacts.map((item, index) => ({
                                  id: index, // Use index as ID
                                  name:
                                    (item?.properties?.firstname?.value ||
                                      "user") +
                                    (item?.properties?.lastname?.value || ""),
                                  phone:
                                    item?.properties?.phone?.value ||
                                    "+1 (415) 555‑0132",
                                  email:
                                    item?.properties?.email?.value ||
                                    "user@gmail.com",
                                }))
                              : []
                          );
                        }}
                        checked={
                          hubspotContacts.length > 0 &&
                          contacts.length === hubspotContacts.length
                        }
                      />
                    </div>
                    <div className="second">
                      <h6 className="blackcolor Carmen-bold-font">Name</h6>
                    </div>
                    <div className="third">
                      <h6 className="blackcolor Carmen-bold-font">Phone</h6>
                    </div>
                    <div className="forth">
                      <h6 className="blackcolor Carmen-bold-font">Email</h6>
                    </div>
                  </div>
                  {hubspotContactsloader ? (
                    <div
                      className="d-flex align-items-center m-auto"
                      style={{ height: "100px" }}
                    >
                      <span className="spinner"></span>
                    </div>
                  ) : hubspotContacts.length > 0 ? (
                    hubspotContacts.map((item, index) => (
                      <div className="contact-detail" key={index}>
                        <div className="ist">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setContacts((prev) =>
                                isChecked
                                  ? [
                                      ...prev,
                                      {
                                        id: index, // Use index as ID
                                        name:
                                          (item?.properties?.firstname?.value ||
                                            "user") +
                                          (item?.properties?.lastname?.value ||
                                            ""),
                                        phone:
                                          item?.properties?.phone?.value ||
                                          "+1 (415) 555‑0132",
                                        email:
                                          item?.properties?.email?.value ||
                                          "user@gmail.com",
                                      },
                                    ]
                                  : prev.filter(
                                      (contact) => contact.id !== index
                                    )
                              );
                            }}
                            checked={contacts.some(
                              (contact) => contact.id === index
                            )}
                          />
                        </div>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {(item?.properties?.firstname?.value || "user") +
                              (item?.properties?.lastname?.value || "")}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item?.properties?.phone?.value ||
                              "+1 (415) 555‑0132"}
                          </p>
                        </div>
                        <div className="forth">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item?.properties?.email?.value || "user@gmail.com"}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="mx-auto py-4">No data</p>
                  )}
                </div>
              </div>

              <Button
                text="Create"
                className="primary-button w-100 fs-16 ms-auto"
                maxWidth="98px"
                padding="10px 20px"
                disabled={btnLoader ? true : false}
                onClick={() => {
                  crateHubspotList();
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Contactslist;
