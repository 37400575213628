import React, { useContext, useEffect, useRef, useState } from "react";
import "./monitoring.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import ReactAudioPlayer from "react-audio-player";
import Inputdash from "../Inputdash/inputdash";
import Button from "../button/button";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { socket } from "../../socket";
import { io } from "socket.io-client";
import axios from "axios";
import { CampaignDataContext } from "../../App";
import { toast } from "react-toastify";
import AppContext from "antd/es/app/context";

function Monitoring() {
  const navigate = useNavigate();
  const [recordingLoading, setRecordingLoading] = useState(false);
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const Apiurl = process.env.REACT_APP_API_KEY;
  const { id } = useParams();
  const logintoken = localStorage.getItem("authToken");
  const [btnActive, setBtnActive] = useState("");
  const [active, setActive] = useState("");
  const [activecall, setActivecall] = useState(0);
  const [messagemodal, setMessagemodal] = useState(false);
  const [emailmodal, setEmailmodal] = useState(false);
  const [transfermodal, setTransfermodal] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const socketRef = useRef(null);
  const [callDetails, setcallDetails] = useState("");
  const [transcription, setTranscription] = useState([]);
  const handleMessageClose = () => setMessagemodal(false);
  const handleEmailClose = () => setEmailmodal(false);
  const handleTransferClose = () => setTransfermodal(false);
  const handleTransferShow = () => setTransfermodal(true);
  const [callLoader, setCallLoader] = useState(false);
  const [transLoader, setTransLoader] = useState(false);
  const { socketData, setSocketData } = useContext(CampaignDataContext);
  const { buttonStatus } = useContext(AppContext);
  const accountSID = "ACb43c8c6cc6de5931c1b1de37449cae97";
  const authToken = "3e08ce6776ab8e4f6d29c67b4d729f64";
  const location = useLocation();
  const audioRef = useRef(null);
  const modalRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transferCount, setTransferCount] = useState("");
  const [transferLoader, setTransferLoader] = useState(false);
  const hubspotEnv = process.env.REACT_APP_AWS_BASE_URL;
  const handleshowModal = () => {
    setShowadd(!showadd);
  };
  const handleActive = (e, inputNumber) => {
    setActive(e);
    console.log("socketData", socketData);
    console.log("number", inputNumber);
    const matchedContacts = []; // Array to store the matched contact with call details

    // Loop through each entry in callInitiatedList
    if (socketData?.callInitiatedList) {
      for (let i = 0; i < socketData?.callInitiatedList?.length; i++) {
        const callInitiatedEntry = socketData.callInitiatedList[i];
        console.log("callInitiatedEntry", callInitiatedEntry);
        const { phone, telephony_id, conversation_id } = callInitiatedEntry;
        console.log("phone", callInitiatedEntry.phone);
        // Check if the current entry's number matches the input number

        if (phone === inputNumber) {
          // Find the contact in contactslist based on the matching number
          const matchingContact = socketData.contactslist.find(
            (contact) => normalizePhoneNumber(contact.phone) === phone
          );

          if (matchingContact) {
            console.log(
              `Match found for ${matchingContact.name} with phone number: ${phone}, ${conversation_id},${telephony_id}`
            );

            // Call functions with the matching contact's data
            getDetails(conversation_id);
            handleCallTranscription(conversation_id);
            callFunc(telephony_id);

            // Add the matching contact with call details to the array
            matchedContacts.push({
              ...matchingContact,
              telephony_id,
              conversation_id,
            });
          } else {
            console.log(`No match found for contact phone number: ${phone}`);
          }

          // Exit the loop once the specific number is found
          break;
        } else console.log("HEllo");
      }
    } else {
      console.log("socketData", socketData.status);

      for (let i = 0; i < socketData?.status?.length; i++) {
        const callInitiatedEntry = socketData?.status[i];
        console.log("callInitiatedEntry", callInitiatedEntry);
        const { phone, telephony_id, conversation_id } = callInitiatedEntry;
        console.log("phone", callInitiatedEntry.phone);
        // Check if the current entry's number matches the input number

        if (phone === inputNumber) {
          // Find the contact in contactslist based on the matching number
          const matchingContact = socketData.contactslist.find(
            (contact) => normalizePhoneNumber(contact.phone) === phone
          );

          if (matchingContact) {
            console.log(
              `Match found for ${matchingContact.name} with phone number: ${phone}, ${conversation_id},${telephony_id}`
            );

            // Call functions with the matching contact's data
            getDetails(conversation_id);
            handleCallTranscription(conversation_id);
            callFunc(telephony_id);

            // Add the matching contact with call details to the array
            matchedContacts.push({
              ...matchingContact,
              telephony_id,
              conversation_id,
            });
          } else {
            console.log(`No match found for contact phone number: ${phone}`);
          }

          // Exit the loop once the specific number is found
          break;
        } else console.log("HEllo");
      }

      return;
    }

    // Optionally log or return matchedContacts if needed
    console.log("Matched Contacts with Call Details:", matchedContacts);
    // return matchedContacts; // Uncomment if you need to return matchedContacts from this function
  };

  const handleActivecall = (e) => {
    setActivecall(e);
  };

  const handleCallTranscription = (trans) => {
    setTranscriptionLoading(true);
    setTransLoader(true);
    setTranscription([]);
    console.log(trans);

    if (trans) {
      axios
        .get(`${hubspotEnv}/get_transcription/${trans}`, {
          headers: {
            Authorization: `Bearer ${logintoken}`,
          },
        })
        .then((response) => {
          setTranscription(response.data.transcription);
          setTransLoader(false);
          setTranscriptionLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the data:", error);
          // toast.error("Transcription not found", { toastId: "transerr" });
          setTranscription([]);
          setCallLoader(false);
          setTranscriptionLoading(false);
        });
    } else {
      setTranscription([]);
      setCallLoader(false);
      setTranscriptionLoading(false);
    }
  };

  const getDetails = (trans) => {
    console.log("trans", trans);
    setDetailLoading(true);
    axios
      .post(`${hubspotEnv}/check-lead`, {
        conversation_id: trans,
      })
      .then((response) => {
        setcallDetails(response.data);
        console.log(response.data);
        setDetailLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setDetailLoading(false);
      });
  };
  // Call func

  const callFunc = async (id) => {
    setCallLoader(true);
    setAudioUrl("");
    setRecordingLoading(true);
    const fileFormat = "mp3";
    const listRecordingsUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings.json?CallSid=${id}`;

    const response = await axios({
      method: "get",
      url: listRecordingsUrl,
      auth: {
        username: accountSID,
        password: authToken,
      },
    });
    const recordings = response.data.recordings;
    if (recordings?.length === 0) {
      setRecordingLoading(false);

      console.log("No recordings found for this Call SID.");
      return;
    }
    console.log("recordings", recordings);
    const recordingSid = recordings[0].sid;
    const recordingUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings/${recordingSid}.${fileFormat}`;
    try {
      const responseAudio = await axios({
        method: "get",
        url: recordingUrl,
        auth: {
          username: accountSID,
          password: authToken,
        },
        responseType: "arraybuffer",
      });

      const audioBlob = new Blob([responseAudio.data], {
        type: `audio/${fileFormat}`,
      });
      const audioObjectUrl = URL.createObjectURL(audioBlob);

      const audio = new Audio(audioObjectUrl);

      audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        if (duration > 0 && duration <= 2) {
          console.log("Valid audio, duration:", duration, "seconds");
        } else if (duration === 0) {
          console.log("Audio is empty or invalid");
        } else {
          console.log("Audio duration exceeds 2 seconds:", duration, "seconds");
          setAudioUrl(audioObjectUrl);
        }
      });

      setCallLoader(false);
      setRecordingLoading(false);
    } catch (error) {
      setCallLoader(false);
      console.error(`Failed to download recording: ${error.message}`);
      setRecordingLoading(false);
    }
  };

  const transferCallApi = () => {
    setTransferLoader(true);
    axios
      .post(`${hubspotEnv}/api/campaigns/check-campaign`, {
        campaign_id: id,
      })
      .then((response) => {
        // setcallDetails(response.data);
        console.log(response.data.count);
        setTransferCount(response.data.count);
        setTransferLoader(false);
        // setDetailLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setTransferLoader(false);
        // setDetailLoading(false);
      });
  };

  useEffect(() => {
    console.log("socketData", socketData);
  }, [socketData]);

  const transfercalls = [
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
    {
      customer: "James",
      agent: "James",
    },
  ];

  function startCampaign() {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/StartCampagin`,
          // `https://24f0-154-192-139-33.ngrok-free.app/customer/StartCampagin`,
          {
            campaignNameID: id,
          },
          {
            headers: {
              Authorization: `Bearer ${logintoken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("error starting campaign", err);
    }
  }
  function ResumeCampagin() {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/Re_StartCampagin`,
          // `https://24f0-154-192-139-33.ngrok-free.app/customer/StartCampagin`,
          {
            campaignNameID: id,
          },
          {
            headers: {
              Authorization: `Bearer ${logintoken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("error resuming campaign", err);
    }
  }

  function stopCampaign() {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/StopCampaign`,
          // `https://24f0-154-192-139-33.ngrok-free.app/customer/StopCampaign`,
          {
            campaignNameID: id,
          },
          {
            headers: {
              Authorization: `Bearer ${logintoken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("error starting campaign", err);
    }
  }

  function normalizePhoneNumber(phoneNumber) {
    // Remove any non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, "");
    console.log(cleaned);
    // Check if cleaned number has 10 digits (US standard without country code)
    if (cleaned?.length === 10) {
      // Assume country code is +1 for US if not specified
      return `+1${cleaned}`;
    } else if (cleaned?.length === 11 && cleaned.startsWith("1")) {
      // If it already has 11 digits and starts with '1', assume it's valid
      return `+${cleaned}`;
    } else {
      // Return null or throw an error if the input format is invalid
      console.warn("Invalid phone number format");
      return null;
    }
  }
  // function normalizePhoneNumber(phoneNumber) {
  //   if (!phoneNumber) return "";

  //   let normalized = phoneNumber.replace(/\D/g, "");

  //   if (normalized.startsWith("1") && normalized.length > 10) {
  //     normalized = normalized.substring(2);
  //   }

  //   if (normalized.length > 10) {
  //     normalized = normalized.slice(-10);
  //   }
  //   console.log("Normalized", normalized);
  //   return normalized;
  // }
  useEffect(() => {
    transferCallApi();
  }, []);

  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  return (
    <section className="monitoring-sec">
      <div className="d-flex align-items-center justify-content-start gap-3">
        <Icon
          icon="ep:back"
          width="24"
          height="24"
          style={{ color: "#0DA359" }}
          className="pointer"
          onClick={() => {
            navigate("/campaigns");
          }}
        />
        <h3 className="heading primarycolor Carmen-semibold-font">
          Campaign Detail
        </h3>
      </div>
      <div className="top">
        <div className="info">
          <div className="d-flex flex-column gap-2">
            <h4 className="Carmen-regular-font greycolor">Transferred Calls</h4>
            {/* <Button
              text="View"
              className="primary-button w-100 fs-16 "
              maxWidth="100px"
              padding="10px 16px"
              onClick={handleTransferShow}
            /> */}
          </div>

          <div className="active-circle">
            <h1 className="primarycolor Carmen-medium-font text-center">
              {transferCount ? transferCount : "0"}
            </h1>
          </div>
        </div>

        <div className="info">
          <h4 className="Carmen-regular-font greycolor">Calls Connected</h4>
          <div className="active-circle">
            <h1 className="primarycolor Carmen-medium-font text-center">
              {socketData?.callEnded ? socketData?.callEnded : "0"}
            </h1>
          </div>
        </div>
        <div className="info">
          <div className="d-flex flex-column gap-2">
            <h4 className="Carmen-regular-font greycolor">Total Calls</h4>
            {btnActive === "start" ||
            btnActive === "restart" ||
            location?.state?.status === "started" ? (
              <Button
                text="Stop Campaign"
                className="primary-button w-100 fs-16"
                maxWidth="170px"
                padding="10px 16px"
                onClick={() => {
                  stopCampaign();
                  setBtnActive("stop");
                }}
              />
            ) : btnActive === "stop" ||
              location?.state?.status === "stopped" ? (
              <>
                <Button
                  text="Restart Campaign"
                  className="primary-button w-100 fs-16"
                  maxWidth="240px"
                  padding="10px 16px"
                  onClick={() => {
                    startCampaign();
                    setBtnActive("restart");
                  }}
                />
                <Button
                  text="Resume Campaign"
                  className="primary-button w-100 fs-16"
                  maxWidth="240px"
                  padding="10px 16px"
                  onClick={() => {
                    ResumeCampagin();
                    setBtnActive("restart");
                  }}
                />
              </>
            ) : location?.state?.status === "completed" ? (
              <Button
                text="Restart Campaign"
                className="primary-button w-100 fs-16"
                maxWidth="240px"
                padding="10px 16px"
                onClick={() => {
                  startCampaign();
                  setBtnActive("restart");
                }}
              />
            ) : location?.state?.status === "new" ? (
              <Button
                text="Start Campaign"
                className="primary-button w-100 fs-16"
                maxWidth="170px"
                padding="10px 16px"
                onClick={() => {
                  startCampaign();
                  setBtnActive("start");
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="active-circle">
            <h1 className="primarycolor Carmen-medium-font text-center">
              {socketData?.total ? socketData?.total : "0"}
            </h1>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="customers-head">
            <h4 className="Carmen-regular-font greycolor">Customers</h4>
            <div className="search-bar">
              <img
                src="/assets/icons/search.svg"
                alt="..."
                className="pointer"
              />
              <input
                type="text"
                placeholder="Search here"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term
              />
            </div>
          </div>
          <div className="customers-list customscrollbar">
            {socketData?.contactslist?.filter((item) => {
              const normalizedSearch = searchTerm?.toLowerCase();
              return (
                item?.name?.toLowerCase()?.includes(normalizedSearch) ||
                normalizePhoneNumber(item.phone)?.includes(normalizedSearch)
              );
            })?.length === 0 ? (
              <p className="m-auto">No data</p>
            ) : (
              socketData?.contactslist
                ?.filter((item) => {
                  const normalizedSearch = searchTerm?.toLowerCase();
                  console.log("item~~~~~~~~~~~~~", item);
                  return (
                    item?.name?.toLowerCase()?.includes(normalizedSearch) ||
                    normalizePhoneNumber(item.phone).includes(normalizedSearch)
                  );
                })

                .map((item, index) => {
                  let contactStatus = "No Status";
                  let statusItem = null;
                  const contactPhoneNormalized = normalizePhoneNumber(
                    item.phone
                  );

                  if (Array.isArray(socketData?.status)) {
                    statusItem = socketData.status.find((status) => {
                      console.log("status~~~~~~~~~~~~~~~~", status);
                      const statusPhoneNormalized = normalizePhoneNumber(
                        status.phone
                      );

                      return statusPhoneNormalized === contactPhoneNormalized;
                    });
                    if (statusItem) {
                      contactStatus = statusItem.status;
                    }
                  } else if (
                    typeof socketData?.status === "object" &&
                    (socketData?.status?.phone || socketData?.status?.phone)
                  ) {
                    const statusPhoneNormalized = normalizePhoneNumber(
                      socketData.status.phone || socketData.status.phone
                    );

                    if (statusPhoneNormalized === contactPhoneNormalized) {
                      statusItem = socketData.status;
                      console.log("socketData status", socketData.status);
                      contactStatus = statusItem.status;
                    }
                  } else if (typeof socketData?.status === "string") {
                    contactStatus = socketData.status;
                  }
                  console.log("log", statusItem?.phone);
                  return (
                    <div
                      key={item._id}
                      className={`pointer ${active === index ? "active" : ""}`}
                      onClick={() =>
                        handleActive(index, statusItem?.phone || "")
                      }
                    >
                      <img
                        src="/assets/icons/calling.svg"
                        alt="..."
                        className="calling-icon"
                      />
                      <h5 className="Carmen-regular-font">{item.name}</h5>
                      <h5 className="Carmen-regular-font ms-auto">
                        {contactStatus}
                      </h5>
                    </div>
                  );
                })
            )}
          </div>
        </div>
        <div className="center">
          <div className="call-recordings customscrollbar">
            <h4 className="Carmen-regular-font greycolor w-100">
              Call Recording
            </h4>
            <div className="w-100">
              {recordingLoading ? (
                <div
                  className="d-flex align-items-center justify-content-center m-auto "
                  style={{ height: "100px" }}
                >
                  <span className="spinner"></span>
                </div>
              ) : audioUrl ? (
                <audio controls className="w-100">
                  <source src={audioUrl} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <p>No Audio</p>
              )}
            </div>

            <h4 className="Carmen-regular-font greycolor">Transcription</h4>

            <div className="bg-white px-3 py-2 customscrollbar">
              {transcriptionLoading ? (
                <div
                  className="d-flex align-items-center m-auto "
                  style={{ height: "100px" }}
                >
                  <span className="spinner"></span>
                </div>
              ) : transcription?.length > 0 ? (
                transcription?.map((item) => (
                  <>
                    <h5
                      className={`d-flex ${
                        item.speaker == "human" ? `ms-auto specific-color` : ""
                      }`}
                    >
                      {item.message}
                    </h5>
                  </>
                ))
              ) : (
                <h5>Transcription not found</h5>
              )}
            </div>
          </div>
        </div>
        <div className="right">
          <div className="call-recordings customscrollbar">
            <h4 className="Carmen-regular-font greycolor w-100">Details</h4>
            {detailLoading ? (
              <div
                className="d-flex align-items-center justify-content-center m-auto "
                style={{ height: "200px" }}
              >
                <span className="spinner"></span>
              </div>
            ) : callDetails ? (
              <div>
                <h5 className="Carmen-bold-font ">Summary</h5>
                <p>
                  {callDetails.summary
                    ? callDetails.summary
                    : "Details not found"}
                </p>
                <div className="d-flex flex-row align-items-center gap-3">
                  <h5 className="Carmen-bold-font ">Lead</h5>
                  <p>{callDetails.lead ? callDetails.lead : ""}</p>
                </div>
              </div>
            ) : (
              <p>No data</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={messagemodal}
        onHide={handleMessageClose}
        centered
        className="knowledgebase-modal"
      >
        <Modal.Body>
          <img
            src="/assets/icons/modalclose.svg"
            alt="..."
            className="pointer close-btn"
            onClick={handleMessageClose}
          />
          <h2 className="Carmen-semibold-font primarycolor">
            Send Reminder via Message
          </h2>
          <div className="modal-inner">
            <Inputdash
              type="number"
              label="Phone no"
              placeholder="+123456789"
            />
            <Inputdash label="Reminder Note" placeholder="Lorem ipsum" />

            <Button
              text="Send"
              className="primary-button w-100 fs-16 "
              maxWidth="131px"
              padding="10px 16px"
              onClick={handleMessageClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={emailmodal}
        onHide={handleEmailClose}
        centered
        className="knowledgebase-modal"
      >
        <Modal.Body>
          <img
            src="/assets/icons/modalclose.svg"
            alt="..."
            className="pointer close-btn"
            onClick={handleEmailClose}
          />
          <h2 className="Carmen-semibold-font primarycolor">
            Send Reminder via Email
          </h2>
          <div className="modal-inner">
            <Inputdash
              type="email"
              label="Email"
              placeholder="User@gmail.com"
            />
            <Inputdash label="Reminder Note" placeholder="Lorem ipsum" />

            <Button
              text="Send"
              className="primary-button w-100 fs-16 "
              maxWidth="131px"
              padding="10px 16px"
              onClick={handleEmailClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={transfermodal}
        onHide={handleTransferClose}
        centered
        className="transfercalls-modal"
      >
        <Modal.Body>
          <img
            src="/assets/icons/modalclose.svg"
            alt="..."
            className="pointer close-btn"
            onClick={handleTransferClose}
          />
          <h2 className="Carmen-semibold-font primarycolor">
            Transferred Calls
          </h2>
          <div className="modal-inner">
            <div className="modal-head">
              <h5 className="Carmen-semibold-font darkcolor customers-div">
                Customers
              </h5>
              <div className="transfer-div"></div>
              <h5 className="Carmen-semibold-font darkcolor customers-div">
                Agents
              </h5>
            </div>
            <div className="modal-details customscrollbar">
              {transfercalls.map((item, index) => (
                <>
                  <div className="detail">
                    <div className="customers-div">
                      <img
                        src="/assets/icons/calling.svg"
                        alt="..."
                        className="calling-icon"
                      />
                      <h5 className="Carmen-regular-font greycolor">
                        James Adam
                      </h5>
                    </div>
                    <div className="transfer-div">
                      <img src="/assets/icons/transfer.svg" alt="" />
                    </div>
                    <div className="customers-div">
                      <img
                        src="/assets/icons/user.svg"
                        alt="..."
                        className="calling-icon"
                      />
                      <h5 className="Carmen-regular-font greycolor">
                        James Adam
                      </h5>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Monitoring;
