import React, { useContext, useEffect, useState } from "react";
import "./agents.scss";
import Button from "../../button/button";
import { Form, Modal } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { ActiveProvider, CampaignDataContext, DataContext } from "../../../App";
import Inputdash from "../../Inputdash/inputdash";
import { toast } from "react-toastify";
import { socket } from "../../../socket";
import { Icon } from "@iconify/react/dist/iconify.js";
function Agents() {
  const hubspot = process.env.REACT_APP_AWS_BASE_URL;

  const navigate = useNavigate();
  const [callagent, setCallagent] = useState("");
  const [alldata, setAlldata] = useState({});
  const [number_to_call, setNumber_to_call] = useState("");
  const { data, setData } = useContext(DataContext);
  const { updateagent, setUpdateagent } = useContext(DataContext);
  const { isActive, setIsActive } = useContext(CampaignDataContext);
  const [call, setCall] = useState(false);
  const [calldetail, setCalldetail] = useState(false);
  const [createShow, setCreateShow] = useState(false);
  const [deleteAgentid, setDeleteAgentid] = useState("");
  const [deleteAgentloader, setDeleteAgentloader] = useState(false);
  const [show, setShow] = useState(false);
  const [editbtnloader, setEditbtnloader] = useState(false);
  const [testbtnloader, setTestbtnloader] = useState(false);
  const [telephonyId, setTelephonyId] = useState("");
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [email, setEmail] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [callStatus, setCallStatus] = useState(null);
  const [isCancelledByUser, setIsCancelledByUser] = useState(false);
  const [activeLoader, setactiveLoader] = useState(false);
  // Handle toggle switch change
  const addActive = (id, phoneNumber) => {
    console.log("Dd", { id, phoneNumber });

    inboundTwillio(id, phoneNumber);
  };
  const removeActive = (id, phoneNumber) => {
    setIsActive((prev) => prev.filter((data) => data.id != id));
    // inboundTwillio("unknown", phoneNumber);
  };

  const inboundTwillio = (id, phoneNumber) => {
    setactiveLoader(true);
    axios
      .post(`${hubspot}/configure_inbound_twilio_url`, {
        phone_number: phoneNumber,
        forwarding_url: `${hubspot}/inbound_Call/${id}`,
      })
      .then((response) => {
        console.log("hello", response);
        setIsActive((prev) => [...prev, { id, phoneNumber }]);
        setactiveLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setactiveLoader(false);
      });
  };
  //
  // Web socket
  // useEffect(() => {
  //   function onConnect() {
  //     console.log("Connected");
  //   }

  //   function onDisconnect() {
  //     console.log("DisConnected");
  //   }

  //   function onFooEvent(value) {
  //     console.log("message");
  //   }

  //   socket.onopen("connect", onConnect);
  //   socket.on("disconnect", onDisconnect);
  //   socket.on("message", onFooEvent);

  //   return () => {
  //     socket.off("connect", onConnect);
  //     socket.off("disconnect", onDisconnect);
  //     socket.off("message", onFooEvent);
  //   };
  // }, []);
  const connectWebSocket = (telephonyId) => {
    const conversationId = telephonyId;
    console.log("Socket function called ");

    const socket = new WebSocket(
      `wss://bud.loanbud.cc/ws?conversation_id=${conversationId}`
    );

    socket.onopen = () => {
      console.log("Connected to WebSocket");
    };

    socket.onmessage = (event) => {
      console.log("Message from server:", event.data);
      if (event.data === "in-progress") {
        setIsRunning(true);
      }

      setCallStatus(event.data);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };

  // useEffect(() => {
  //   if (
  //     callStatus == "no-answer" ||
  //     callStatus == "completed" ||
  //     callStatus == "busy" ||
  //     callStatus == "failed" ||
  //     callStatus == "canceled"
  //   ) {
  //     setCallStatus(null);
  //   }
  // }, [callStatus]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const handleCall = () => {
    setCall(false);
    setEmail("");
    setNumber_to_call("");
  };
  const setAgentstate = () => {
    setData({
      _id: "",
      customerID: "",
      AgentName: "",
      CompanyName: "",
      FallBackNote:
        "If you're unsure about the type of loan you need, I can help clarify options based on your business needs and financial goals. Let's explore the best solution together.",
      audioId: "",
      greetings:
        "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?",
      Temperature: 0.2,
      Maximum_Tokens: "654",
      Top_P: "1",
      Frequency_Penality: "0",
      Presence_Penality: "0",
      Model: "",
      Script: "",
      Scriptshow: `**Introduction:**
  "Hello [Client First Name], this is {{agent_name}} from {{company_name}}. I am calling to inform you about our new SBA FastTrack program, designed for businesses looking to expand. It offers up to $350,000 in working capital with a 10-year term and monthly payments. Does your business need any working capital at this time?"
  Your Role:
  If the user's query is from the knowledge base, don't answer off the cuff; refer directly to the knowledge base.
  Personal Information:
  - Your name is {agent_name}.
  - Your company name is {company_name}.
  **Qualifying Questions:**
  1. How long has your business been operating?
  2. Could you share what your business revenue looked like last year?
  3. Do you know your approximate FICO score?
  (Minimum of 2 years required)
  (Minimum of $200,000 required)
  (Minimum of 640 required)
  "Base of above question told him if he qualified for loan"
  **Scheduling the Consultation:**
  "Based on your responses, it sounds like you are a great fit for this program. Let's schedule a consultation to discuss this further. How does [Day], [Date] at [Time] work for you? If that doesn’t work, I have other times available."
  **Confirmation of Appointment:**
  "Perfect, you're all set for [Day], [Date] at [Time]. You will be speaking with [Loan Officer], who is an expert in SBA loans. You will soon receive an email with a link to a secure loan application. Completing this before our meeting will ensure we make the most out of our time together."
  **Objection Handling Tips:**
  - Listen attentively and allow the prospect to fully express their concerns.
  - Acknowledge and validate the prospect's feelings to build trust.
  - Determine the root of any objections to better address them and move towards closing the conversation positively.
  **Advanced Objection Handling Techniques:**
  - "If a prospect is concerned about the time commitment, reassure them that our streamlined process minimizes downtime and maximizes efficiency."
  - "Should the topic of financial readiness arise, discuss our flexible terms that are designed to accommodate various financial states, emphasizing the supportive nature of our program.", confinement:"Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance. If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved. Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments. Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements. Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client. Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive. Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.
  - Send meeting link to email.`,
      confinement: `Use the exact language provided in the script for introduction, qualifying questions, scheduling, and confirmation steps to maintain consistency and compliance.

  If the client’s query is referenced in the knowledge base, answer directly from it without improvisation to ensure all information is accurate and approved.
  
  Personalize the script by filling in placeholders like the client's first name, agent name ({agent_name}), company name ({company_name}), and specific dates, days, and times for appointments.
  
  Ensure clients meet all qualifying criteria (minimum 2 years in operation, $200,000 annual revenue, 640 FICO score) before proceeding to schedule a consultation. Politely inform clients if they do not meet these requirements.
  
  Maintain a neutral tone; focus on suggesting and highlighting the program’s benefits rather than pressuring the client.
  
  Listen to and acknowledge the client’s concerns, ensuring they feel heard and validated. Use the standard objection handling tips and advanced techniques provided to address objections constructively and keep the conversation positive.
  
  Reconfirm appointment details with the client and remind them to complete the pre-meeting application to ensure a productive consultation with the loan officer.`,
      knowledgeBaseId: "",
      phoneNumber: "",
      hubspot_meetinglink: "",
      hubspot_meeting_id: "",
      hubspot_meeting_slug: "",
      hubspot_meeting_name: "",
      hubspot_meeting_type: "",
      hubspot_meeting_organizer_userId: "",
      hubspot_meeting_userIds_of_link_members: [],
    });
  };

  const formatPhoneNumber = (phone) => {
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, "");

    // Assuming the number is US-based and starts with a country code of +1
    if (cleaned.length === 10) {
      // If it's 10 digits, prepend country code +1
      return `+1${cleaned}`;
    } else if (cleaned.length === 12 && cleaned.startsWith("1")) {
      // If it already starts with 1 and is 11 digits, just add + in front
      return `+${cleaned}`;
    }

    // Return the cleaned phone number, assuming it's already formatted
    return `+${cleaned}`;
  };

  const handleChangenumber = (e) => {
    setNumber_to_call(e.target.value);
  };

  const Callingurl = process.env.REACT_APP_AWS_BASE_URL;
  const handleCalldetailshow = () => {
    setCallStatus(null);

    if (number_to_call === "" || email === "") {
      toast.error("Please fill all fields", { toastId: "emptynumber" });
      return;
    } else {
      setTestbtnloader(true);
      axios
        .post(`${Callingurl}/make_outbound_call_route_test_call`, {
          Agent_id: callagent,
          number_to_call: formatPhoneNumber(number_to_call),
          email: email,
        })
        .then((response) => {
          console.log(response);
          setCall(false);
          setNumber_to_call("");
          setCalldetail(true);
          setEmail("");
          setTelephonyId(response.data.telephony_id);
          connectWebSocket(response.data.telephony_id);
        })
        .catch((error) => {
          console.log(error);

          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            const errorMessage =
              error.response.data.detail[0]?.msg ||
              "Invalid phone number format";
            toast.error(errorMessage, { toastId: "emptynumber" });
          } else {
            toast.error("An unexpected error occurred", {
              toastId: "unexpectederror",
            });
          }
          setTestbtnloader(false);
        });
    }
  };
  const handleCalldetail = () => {
    setCalldetail(false);
  };
  const handleEndcall = () => {
    axios
      .post(`${Callingurl}/terminate-this-call`, {
        call_sid: telephonyId,
      })
      .then((response) => {
        console.log(response);
        handleCalldetail();
        setIsRunning(false);
        setSeconds(0);
        setCallStatus(null);
        setTestbtnloader(false);
      })
      .catch((error) => {});
  };
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDeleteAgentid(id);
  };
  const handleCreateclose = () => setCreateShow(false);
  const handleCreateShow = () => {
    setUpdateagent(false);
    setCreateShow(true);
  };
  const [basename, setBasename] = useState("");
  // const navigatecreate = () => {
  //   if (data.AgentTitle && basename) {
  //     navigate("/create-agent", {
  //       state: { data: data.AgentTitle, basename: basename },
  //     });
  //   } else {
  //     console.log("AgentTitle or basename is empty");
  //   }
  // };
  // const navigateToagent = (id) => {
  //   specificapi(id);

  // };
  const specificapi = (id) => {
    setEditbtnloader(true);
    axios
      .get(`${Apiurl}/getAgent/${id}`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        setData(response.data.response._doc);
        setBasename(response.data.response.knowledgeBase.name);
        // console.log(data);
        // if (data.AgentTitle && basename) {
        // navigate("/create-agent", {
        //   state: { data: data.AgentTitle, basename: basename },
        // });
        // } else {
        //   console.log("AgentTitle or basename is empty");
        // }
        setUpdateagent(true);
        setEditbtnloader(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setEditbtnloader(false);
      });
  };
  // Timer for call
  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);
  // const startTimer = () => {
  //   setIsRunning(true);
  // };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  //
  useEffect(() => {
    if (data.AgentTitle && basename) {
      navigate("/create-agent", {
        state: { data: data.AgentTitle, basename: basename },
      });
    }
  }, [data, basename]);
  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const responseapi = () => {
    setLoader(true);
    axios
      .get(`${Apiurl}/getAllAgent`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((res) => {
        setAlldata(res.data.response || []);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const deleteAgent = (id) => {
    setDeleteAgentloader(true);
    axios
      .delete(`${Apiurl}/deleteAgent/${id}`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, { toastId: "deleteSuccess" });
        handleClose();
        responseapi();
        setDeleteAgentloader(false);
      })
      .catch((err) => {
        console.log(err);

        setDeleteAgentloader(false);
      });
  };

  useEffect(() => {
    responseapi();
  }, []);

  return (
    <div className="dash-main">
      <section className="agents-sec">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">
            Create Agent
          </h3>
          <Button
            text="Create"
            className="primary-button w-100 fs-16 "
            maxWidth="94px"
            padding="10px 18px"
            onClick={handleCreateShow}
          />
        </div>
        {loader ? (
          <div className="spinner-outer">
            <span className="spinner"></span>
          </div>
        ) : (
          <div className="agents-cards">
            {Array.isArray(alldata) && alldata.length > 0 ? (
              alldata.map((item, index) => {
                console.log(item._id);
                return (
                  <div className="agent-card" key={item._id}>
                    <div className="card-top">
                      <div className="left">
                        <h4 className="Carmen-bold-font darkcolor">
                          {item.AgentName}
                        </h4>
                        <div className="bound">
                          <p className="Carmen-bold-font secondarycolor">
                            {item.AgentTitle}
                          </p>
                          <div className="bound-layer"></div>
                        </div>
                      </div>
                      <img
                        src="/assets/icons/delete.svg"
                        alt="... "
                        className="right pointer"
                        onClick={() => handleShow(item._id)}
                      />
                    </div>
                    <h5 className="Carmen-regular-font greycolor">
                      {item.FallBackNote}
                    </h5>
                    {item.AgentTitle == "Inbound_Call" ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>
                          <span className="Carmen-bold-font">
                            Agent Number :{" "}
                          </span>
                          {item.phoneNumber}
                        </h5>
                        {activeLoader ? (
                          <span>Loading...</span>
                        ) : (
                          <div className="d-flex align-items-center">
                            <Form.Label className="me-2">
                              {isActive.find((data) => data.id == item._id) !=
                              undefined ? (
                                <h5 className="Carmen-regular-font greycolor">
                                  Active
                                </h5>
                              ) : (
                                <h5 className="Carmen-regular-font greycolor">
                                  Inactive
                                </h5>
                              )}
                            </Form.Label>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label=""
                              checked={
                                isActive.find((data) => data.id == item._id) !=
                                undefined
                              }
                              onChange={(e) => {
                                console.log("d", item._id);
                                console.log("d", isActive);
                                if (e.target.checked) {
                                  const isFound = isActive.find(
                                    (data) =>
                                      data.phoneNumber == item.phoneNumber
                                  );
                                  if (isFound != undefined) {
                                    removeActive(isFound.id);
                                  }
                                  addActive(item._id, item.phoneNumber);
                                } else {
                                  removeActive(item._id, item.phoneNumber);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex gap-3">
                      <Button
                        text="Edit"
                        className="primary-button w-100 fs-16"
                        maxWidth="70px"
                        padding="8px 16px"
                        onClick={() => {
                          specificapi(item._id);
                          // navigatecreate();
                        }}
                        disabled={editbtnloader ? true : false}
                      />
                      {item.AgentTitle == "Outbound_Call" ? (
                        <Button
                          text="Test Agent"
                          className="secondary-btn w-100 fs-16 "
                          maxWidth="130px"
                          padding="8px 16px"
                          onClick={() => {
                            setCall(true);
                            setCallagent(item._id);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No agents found.</p>
            )}
          </div>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleClose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-20 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={() => deleteAgent(deleteAgentid)}
                disabled={deleteAgentloader ? true : false}
              />
              <Button
                text="No"
                className="primary-button fs-20 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handleClose}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={createShow}
          onHide={handleCreateclose}
          centered
          className="create-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleCreateclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              What you want to create?
            </h2>
            <Button
              text="In-bound Agent"
              className="primary-button w-100 fs-20 mx-auto"
              maxWidth="210px"
              padding="13px 5px"
              onClick={() => {
                setAgentstate();
                navigate("/create-agent", { state: { data: "Inbound_Call" } });
              }}
            />
            <p className="Carmen-regular-font greycolor text-center">OR</p>
            <Button
              text="Out-bound Agent"
              className="primary-button w-100 fs-20 mx-auto"
              maxWidth="210px"
              padding="13px 5px"
              onClick={() => {
                setAgentstate();
                navigate("/create-agent", { state: { data: "Outbound_Call" } });
              }}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={call}
          onHide={handleCall}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleCall}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Test your agent
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Add Phone Number"
                placeholder="Phone no"
                name="number_to_call"
                onchange={handleChangenumber}
                value={number_to_call}
              />
              <Inputdash
                type="email"
                label="Add Email"
                placeholder="Email"
                name="email"
                onchange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />

              <Button
                text="Call"
                className="primary-button w-100 fs-16 "
                maxWidth="131px"
                padding="10px 16px"
                img="/assets/icons/PhoneCall.svg"
                onClick={handleCalldetailshow}
                disabled={testbtnloader ? true : false}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* call detail modal */}
        <Modal show={calldetail} centered className="call-detail">
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              style={{ position: "absolute", top: "20px", right: "20px" }}
              onClick={handleEndcall}
            />
            <h4 className="Carmen-semibold-font primarycolor">
              {callStatus == null ? "Initiating ..." : callStatus}
            </h4>
            <div>
              {callStatus == "no-answer" ||
              callStatus == null ||
              callStatus == "busy" ||
              callStatus == "failed" ||
              callStatus == "canceled" ? (
                ""
              ) : callStatus == "completed" ? (
                <Icon
                  icon="fluent-mdl2:completed"
                  width="30"
                  height="30"
                  style={{ color: "#0DA359" }}
                />
              ) : (
                <img src="/assets/gifs/call.gif" alt="..." />
              )}
              {callStatus == "in-progress" ? (
                <p className="Carmen-semibold-font primarycolor">
                  {formatTime(seconds)}
                </p>
              ) : (
                ""
              )}
            </div>
            {callStatus == "no-answer" ||
            callStatus == "completed" ||
            callStatus == "busy" ||
            callStatus == "failed" ||
            callStatus == "canceled" ? (
              ""
            ) : (
              <Button
                text="End"
                className="danger-button w-100 fs-16 mx-auto"
                maxWidth="131px"
                padding="10px 16px"
                onClick={handleEndcall}
                img="/assets/icons/PhoneCall2.svg"
              />
            )}
          </Modal.Body>
        </Modal>

        <Outlet />
      </section>
    </div>
  );
}

export default Agents;
