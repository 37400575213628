import React, { useContext, useEffect, useState } from "react";
import "./compaignsInrun.scss";
import Button from "../../button/button";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Inputdash from "../../Inputdash/inputdash";
import Monitoring from "../../monitoring/monitoring";
import axios from "axios";
import { socket } from "../../../socket";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { setDate } from "rsuite/esm/internals/utils/date";
import { CampaignDataContext } from "../../../App";
import { formatPhoneNumber } from "../../../helperFunctions/helperFunctions";
import { Dropdown } from "primereact/dropdown";
import AppContext from "antd/es/app/context";
function CompaignsInrun() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const { socketData, setSocketData, setButtonStatus } =
    useContext(CampaignDataContext);
  const [contactsList, setContactsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [filters, setFilters] = useState({
    campaignName: "",
    status: "",
  });

  // Filter logic
  const filteredData = campaignData?.filter((item) => {
    return (
      (!filters.campaignName ||
        item.campaignName
          .toLowerCase()
          .includes(filters.campaignName.toLowerCase())) &&
      (!filters.status || item.status === filters.status)
    );
  });
  const handledeleteshow = () => {
    setDeleteModal(true);
  };
  const handledeleteclose = () => {
    setdeleteid("");
    setDeleteModal(false);
  };
  // const [show, setShow] = useState(false);
  const [detailmodal, setDetailmodal] = useState(false);

  const handleDetailClose = () => setDetailmodal(false);
  const handleDetailShow = () => {
    console.log("j");
    setDetailmodal(true);
  };
  // const handleClose = () => setShow(false);
  const createCompaign = () => {
    navigate("/create-campaign");
  };
  // Delete Campaign

  const handleDelete = () => {
    setDeleteLoader(true);
    axios
      .delete(`${Apiurl}/campaigns/${deleteid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        console.log(res);

        toast.success(res.data.message, { toastId: "deleteSuccess" });
        getCampaigns();
        setDeleteLoader(false);
        handledeleteclose();
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "deleteerr" });
        setDeleteLoader(false);
      });
  };

  // Campaigns get API
  const getCampaigns = () => {
    setLoader(true);
    axios
      .get(`${Apiurl}/getCampaigns`, {
        headers: {
          "Content-Type": "application/json",
          "User-Agent": "insomnia/10.0.0",
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        console.log(response.data.data); // Access data here in axios response
        setCampaignData(response.data.data); // Use response.data instead of the whole response object
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  const formatPhoneNumber = (phone) => {
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, "");

    // Assuming the number is US-based and starts with a country code of +1
    if (cleaned.length === 10) {
      // If it's 10 digits, prepend country code +1
      return `+1${cleaned}`;
    } else if (cleaned.length === 12 && cleaned.startsWith("1")) {
      // If it already starts with 1 and is 11 digits, just add + in front
      return `+${cleaned}`;
    }

    // Return the cleaned phone number, assuming it's already formatted
    return `+${cleaned}`;
  };
  //
  // Sockets
  // Connect Socket

  let socket; // Ensure that socket is declared in the global scope
  function connectSocket(id, status) {
    const campaignId = id;
    const userToken = logintoken;

    if (!campaignId || !userToken) {
      toast.error("Please provide a Campaign ID and a JWT token.");
      return;
    }

    // console.log(
    //   `Connecting with CampaignID: ${campaignId} and Token: ${userToken}`
    // );

    // Initialize the socket connection
    // http://node.loanbud.cc
    socket = io("wss://node.loanbud.cc", {
      query: {
        userToken: logintoken,
        CampaginID: id,
      },
    });
    let combinedData = [];

    socket.on("connect", () => {
      console.log("Connected");
      // setButtonStatus(status);
      navigate(`/campaign-details/${id}`, { state: { status } });
    });
    socket.on("campaignCompleted", (status) => {
      console.log("status", status);
      setButtonStatus(status);
    });

    socket.on("DataSocketConnected", (data) => {
      combinedData = data;
      // console.log(data);

      setSocketData(combinedData);
    });

    socket.on("disconnect", (reason) => {
      console.log("Disconnected");
    });

    socket.on("campaignStatusUpdate", (data) => {
      // console.log("Call status update:", data);
      // console.log("Call status update:", data);
      const formattedContactsList = data.contactslist.map((contact) => ({
        ...contact,
        phone: formatPhoneNumber(contact.phone),
      }));

      combinedData = {
        ...combinedData, // Keep existing data
        ...data, // Merge new campaignStatusUpdate data
        contactslist: formattedContactsList,
      };

      console.log("Combined Data:", combinedData);
      setSocketData(combinedData);
    });

    socket.on("campaignStatusUpdate", (data) => {
      // console.log("Call status update:", data);
      // console.log("Call status update:", data);
      const formattedContactsList = data.contactslist.map((contact) => ({
        ...contact,
        phone: formatPhoneNumber(contact.phone),
      }));

      combinedData = {
        ...combinedData, // Keep existing data
        ...data, // Merge new campaignStatusUpdate data
        contactslist: formattedContactsList,
      };

      // console.log("Combined Data:", combinedData);
      setSocketData(combinedData);
    });

    socket.on("callStatus", (data) => {
      console.log("Sending", data.status);

      for (let i = 0; i < combinedData.contactslist.length; i++) {
        const contact = combinedData.contactslist[i];
        const formattedPhone = contact.phone;

        console.log(`Checking contact with phone: ${formattedPhone}`);

        // Check if the current contact's phone matches data.number
        if (formattedPhone === data.phone) {
          console.log(`Match found for phone: ${data.number}`);

          // Make sure combinedData.status is an array
          if (!Array.isArray(combinedData.status)) {
            combinedData.status = []; // Initialize it as an empty array if it's not an array
          }

          // Log current status list before the update

          // Find the index of the status object for this contact's number
          const statusIndex = combinedData.status.findIndex(
            (s) => s.phone === data.phone
          );

          if (statusIndex !== -1) {
            // Update the existing status entry

            combinedData.status[statusIndex] = {
              ...combinedData.status[statusIndex],
              status: data.status, // Correctly updating the status
            };
          } else {
            // Push a new status entry if it doesn't exist

            combinedData.status.push({
              phone: data.phone,
              status: data.status, // Correctly assigning the status
            });
            console.log(
              "New status entry added:",
              combinedData.status[combinedData.status.length - 1]
            );
          }

          // Log the final status list after the update
          console.log(
            "Final combinedData.status after update:",
            combinedData.status
          );
          console.log("data 1~~~~~~~~~~~~~", data);
          // Update the state with the new combinedData
          setSocketData({ ...combinedData });
          console.log("State updated with new combinedData.");

          // Stop the loop after finding the match
          break;
        } else {
          console.log(`No match for contact phone: ${formattedPhone}`);
        }
      }

      // console.log(
      //   ":===========================================================",
      //   combinedData
      // );
    });

    socket.on("callInitiated", async (data) => {
      // Extract the necessary properties from the socket data
      const { telephony_id, phone, conversation_id } = data;
      // console.log("Received data from socket:", data);

      // Ensure combinedData.callInitiatedList is an array
      if (!Array.isArray(combinedData.callInitiatedList)) {
        console.log(
          "callInitiatedList is not an array, initializing as an empty array."
        );
        combinedData.callInitiatedList = []; // Initialize it as an empty array if it's not already an array
      }

      // Log the existing contacts list
      // console.log("Existing contacts list:", combinedData.contactslist);

      // Loop through each contact in contactslist to find a matching phone number
      for (let i = 0; i < combinedData.contactslist.length; i++) {
        const contact = combinedData.contactslist[i];
        const formattedPhone = contact.phone;

        // console.log(
        //   `Checking contact at index ${i} with phone: ${formattedPhone}`
        // );

        // Check if the current contact's phone matches the data number
        if (formatPhoneNumber(contact.phone) === data.phone) {
          console.log(`Match found for phone: ${phone}`);

          // Find the index of an existing call entry for this contact's number in callInitiatedList
          const callIndex = combinedData.callInitiatedList.findIndex(
            (c) => c.phone === phone
          );

          // console.log("call index calll initailied", callIndex);

          if (callIndex !== -1) {
            // console.log(
            //   `Existing call entry found at index ${callIndex}. Updating entry.`
            // );
            // Update the existing call entry if found
            combinedData.callInitiatedList[callIndex] = {
              ...combinedData.callInitiatedList[callIndex],
              telephony_id,
              conversation_id,
            };
          } else {
            console.log("No existing call entry found. Adding new entry.");
            // Push a new entry if no matching entry exists
            combinedData.callInitiatedList.push({
              telephony_id,
              phone,
              conversation_id,
            });
          }

          // Log the updated callInitiatedList after modification
          console.log(
            "Updated callInitiatedList:",
            combinedData.callInitiatedList
          );

          // Update the state with the new combinedData
          setSocketData({ ...combinedData });
          console.log("State updated with new combinedData.");

          // Stop the loop after finding and updating the match
          break;
        } else {
          console.log(
            `No match for contact phone: ${formattedPhone} (looking for ${phone})`
          );
        }
      }

      // Log if no matches were found in the contacts list
      if (
        !combinedData.contactslist.some((contact) => contact.phone === phone)
      ) {
        console.log(
          "No matching contact found in contacts list for the phone number:",
          phone
        );
      }
    });

    socket.on("campaignError", (error) => {
      console.error("Campaign error:", error);
    });

    // WebSocket error handling
    socket.on("callError", (error) => {
      console.error("WebSocket call error:", error);
    });
  }

  // Start Campaign

  //
  useEffect(() => {
    getCampaigns();
    setSocketData({});
  }, []);

  return (
    <div className="dash-main">
      <section className="compaignsInrun-sec">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">
            Create New Campaign
          </h3>
          <Button
            text="Create"
            className="primary-button w-100 fs-16 "
            maxWidth="92px"
            padding="10px 14px"
            onClick={createCompaign}
          />
        </div>
        <div className="contacts">
          <div className="dates">
            <div className="dates-inner">
              <h5 className="darkcolor Carmen-bold-font">Search</h5>
              <div className="search-div">
                <img
                  src="/assets/icons/search.svg"
                  alt="..."
                  className="pointer"
                />
                <input
                  type="text"
                  placeholder="Search campaign name "
                  value={filters.campaignName}
                  onChange={(e) =>
                    setFilters({ ...filters, campaignName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="dates-inner">
              <h5 className="darkcolor Carmen-bold-font">Sort by Status</h5>
              <div className="search-div customscrollbar">
                <Dropdown
                  optionLabel="order"
                  placeholder="Select Status"
                  value={filters.status}
                  options={["new", "completed", "stopped"]}
                  onChange={(e) =>
                    setFilters({ ...filters, status: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="contacts-inner">
            <h4 className="darkcolor Carmen-medium-font">Campaigns</h4>
            <div className="contactTable-outer ">
              <div className="contact-table">
                <div className="table-head">
                  <div className="second">
                    <p className="darkcolor Carmen-medium-font">
                      Campaign name
                    </p>
                  </div>
                  <div className="third">
                    <p className="darkcolor Carmen-medium-font">
                      Agent Assigned
                    </p>
                  </div>
                  <div className="forth">
                    <p className="darkcolor Carmen-medium-font">Created at</p>
                  </div>
                  <div className="fifth">
                    <p className="darkcolor Carmen-medium-font">Status</p>
                  </div>
                  <div className="fifth">
                    <p className="darkcolor Carmen-medium-font">Actions</p>
                  </div>
                </div>
                <div className="table-body customscrollbar">
                  {loader ? (
                    <div
                      className="d-flex align-items-center m-auto"
                      style={{ height: "100px" }}
                    >
                      <span className="spinner"></span>
                    </div>
                  ) : filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <div className="contact-detail" key={index}>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.campaignName.length > 40
                              ? `${item.campaignName.substring(0, 40)}...`
                              : item.campaignName}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.agentName}
                          </p>
                        </div>
                        <div className="forth">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.createdAt}
                          </p>
                        </div>
                        <div className="fifth">
                          <p
                            className={`small-para Carmen-medium-font ${
                              item.status === "stopped"
                                ? "redcolor"
                                : item.status === "Completed"
                                ? "bluecolor"
                                : "primarycolor"
                            }`}
                          >
                            {item.status}
                          </p>
                        </div>
                        <div className="fifth">
                          <img
                            src="/assets/icons/delete.svg"
                            alt="..."
                            className="pointer"
                            onClick={() => {
                              handledeleteshow();
                              setdeleteid(item._id);
                            }}
                          />
                          <img
                            src="/assets/icons/view.svg"
                            alt="..."
                            className="pointer"
                            onClick={() => {
                              navigate("/campaign-details/" + item._id);
                              connectSocket(item._id, item.status);
                            }}
                          />
                          <img
                            src="/assets/icons/edit.svg"
                            alt="..."
                            className="pointer"
                            onClick={() => {
                              navigate("/update-campaign", {
                                state: {
                                  id: item._id,
                                  agent: item.agentName,
                                  agentId: item.agent,
                                  campaign: item.campaignName,
                                  contact: item.contactList.fileName,
                                  listId: item.contactList._id,
                                  description: item.campaignDescription,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="m-auto py-4">No data</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal
          show={show}
          onHide={handleClose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleClose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-20 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handleClose}
              />
              <Button
                text="No"
                className="primary-button fs-20 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handleClose}
              />
            </div>
          </Modal.Body>
        </Modal>

         */}
      </section>

      {/* Delete Modal */}

      <Modal
        show={deleteModal}
        onHide={handledeleteclose}
        centered
        className="delete-modal"
      >
        <Modal.Body>
          <img
            src="/assets/icons/modalclose.svg"
            alt="..."
            className="pointer close-btn"
            onClick={handledeleteclose}
          />
          <h3 className="Carmen-semibold-font primarycolor text-center">
            Are you sure you want to delete?
          </h3>
          <div>
            <Button
              text="Yes"
              className="secondary-btn fs-16 mx-auto"
              width="93px"
              padding="8px 24px"
              onClick={handleDelete}
              disabled={deleteLoader ? true : false}
            />
            <Button
              text="No"
              className="primary-button fs-16 mx-auto"
              width="93px"
              padding="8px 24px"
              onClick={handledeleteclose}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* 
      <Modal
        show={detailmodal}
        onHide={handleDetailClose}
        centered
        className="compaigndetail-modal"
      >
        <Modal.Body>
          <div className="detailModal-header">
            <div className="left">
              <h4 className="Carmen-semibold-font primarycolor">
                Campaigns started to sell loan
              </h4>
              <p className="Carmen-semibold-font greycolor">
                Agent Assigned: Agent Name
              </p>
            </div>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleDetailClose}
            />
          </div>

          <Monitoring />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default CompaignsInrun;
